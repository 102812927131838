
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IVeterinarian,
        IParaveterinarian,
        IUser,
        IOrganization,
    } from '@/types';

    import {
        VeterinarianModule,
        ParaveterinarianModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const veterinarianNamespace = namespace('veterinarian');
    const paraveterinarianNamespace = namespace('paraveterinarian');
    const organizationNamespace = namespace('organization');

    import AccountInfos from '@/components/account-profile/AccountInfos.vue';
    import AccountPresentationVeterinarian from '@/components/account-profile/AccountProfilePresentationVeterinarian.vue';
    import AccountPresentationParaveterinarian from '@/components/account-profile/AccountProfilePresentationParaveterinarian.vue';
    import AccountPictures from '@/components/account-profile/AccountProfilePictures.vue';
    import AccountAuthentication from '@/views/AccountAuthentication.vue';
    import AccountEducation from '@/components/account-profile/AccountProfileEducation.vue';

    @Component<AccountProfile>({
        components: {
            AccountInfos,
            AccountPresentationVeterinarian,
            AccountPresentationParaveterinarian,
            AccountPictures,
            AccountEducation,
            AccountAuthentication,
        },
    })
    export default class AccountProfile extends Vue {
        public profileIsFetched: boolean = false;

        @accountNamespace.Getter('isVeterinarian')
        public isVeterinarian!: boolean;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        @veterinarianNamespace.Getter('loggedVeterinarian')
        public loggedVeterinarian!: IVeterinarian;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @paraveterinarianNamespace.Getter('loggedParaveterinarian')
        public loggedParaveterinarian!: IParaveterinarian;

        get uri () {
            return 'https://www.vetolib.fr/'+this.loggedOrganization.slug;
        }

        public created() {
            if (this.isVeterinarian && this.loggedUser.veterinarian) {
                getModule(VeterinarianModule, this.$store)
                    .fetchVeterinarian(this.loggedUser.veterinarian.id)
                    .then(() => {
                        this.profileIsFetched = true;
                    });
            } else if (this.loggedUser.paraveterinarian) {
                getModule(ParaveterinarianModule, this.$store)
                    .fetchParaveterinarian(this.loggedUser.paraveterinarian.id)
                    .then(() => {
                        this.profileIsFetched = true;
                    });
            }
        }

        public scrollToHash() {
            const hash = this.$route.hash;
            this.$nextTick(() => {
                this.$scrollTo(hash, 0);
            });
        }

        public updateInfos() {
            if (this.isVeterinarian && this.loggedUser.veterinarian) {
                getModule(VeterinarianModule, this.$store)
                    .fetchVeterinarian(this.loggedUser.veterinarian.id)
                    .then(() => {
                        this.profileIsFetched = true;
                    });
            } else if (this.loggedUser.paraveterinarian) {
                getModule(ParaveterinarianModule, this.$store)
                    .fetchParaveterinarian(this.loggedUser.paraveterinarian.id)
                    .then(() => {
                        this.profileIsFetched = true;
                    });
            }
        }

        public updated() {
            if (this.$route.hash && this.profileIsFetched) {
                this.scrollToHash();
            }
        }
        
        
    }
