
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        IAccount,
        IAccountRole,
        IOrganization,
    } from '@/types';

    import {
        AccountModule,
    } from '@/store/modules';

    import LinkOrganization from '@/components/LinkOrganization.vue';
    import CreateSubscription from '@/components/link-organization/CreateSubscription.vue';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<OrganizationsTable>({
        components: {
            CreateSubscription,
            LinkOrganization,
        },
    })
    export default class OrganizationsTable extends Vue {
        public isLoading: boolean = true;
        public createDialog: boolean = false;

        public createdOrganization: IOrganization|null = null;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        get headers() {
            return [
                {
                    text: 'Nom',
                    value: 'name',
                },
                {
                    text: 'Adresse',
                    value: 'address',
                },
                {
                    text: 'Role',
                    value: 'role',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                },
            ];
        }

        public openCreateDialog() {
            this.createdOrganization = null;
            this.createDialog = true;
        }

        public leaveOrganization(accountRole: IAccountRole) {
            getModule(AccountModule, this.$store)
                .deleteAccountRole(accountRole)
                .then(() => this.isLoading = false)
            ;
        }

        public created() {
            getModule(AccountModule, this.$store)
                .fetchAccountRoles()
                .then(() => this.isLoading = false)
            ;
        }
    }
