
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Prop} from 'vue-property-decorator';

    import {
        IPostVeterinarianAvatarParams,
        IVeterinarian,
        IPostOrganizationVeterinarianAvatarParams,
        IOrganization,
    } from '@/types';

    import {
        VeterinarianModule,
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const veterinarianNamespace = namespace('veterinarian');
    const organizationNamespace = namespace('organization');

    import EditAvatarDialog from '@/components/dialogs/EditAvatarDialog.vue';
    import AccountPresentationVeterinarian from '@/components/account-profile/AccountProfilePresentationVeterinarian.vue';
    import AccountPresentationParaveterinarian from '@/components/account-profile/AccountProfilePresentationParaveterinarian.vue';

    @Component<AccountInfos>({
        components: {
            EditAvatarDialog,
            AccountPresentationVeterinarian,
            AccountPresentationParaveterinarian,
        },
    })
    export default class AccountInfos extends Vue {
        public isSubmittingAvatar: boolean = false;
        public isLoading: boolean = true;
        public avatarDialog: boolean = false;
        public fileToUpload: File|null = null;
        public presDialog: boolean = false;

        @veterinarianNamespace.Getter('loggedVeterinarian')
        public loggedVeterinarian!: IVeterinarian;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @accountNamespace.Getter('isVeterinarian')
        public isVeterinarian!: boolean;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        public submitAvatar(blob: File) {
            this.isSubmittingAvatar = true;

            const formData = new FormData();
            formData.append('avatar', blob);

            const veterinarianModule = getModule(VeterinarianModule, this.$store);
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (this.loggedVeterinarian && (this.veterinarian.id === this.loggedVeterinarian.id)) {
                const params: IPostVeterinarianAvatarParams = {
                    veterinarian_id: this.veterinarian.id,
                    avatar: formData,
                };

                veterinarianModule
                    .postAvatar(params)
                    .then(() => {
                        snackbarModule.displaySuccess(`Votre avatar a bien été modifié !`);
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                    })
                    .finally(() => {
                        this.isSubmittingAvatar = false;
                        this.avatarDialog = false;
                    })
                ;
            } else {
                const params: IPostOrganizationVeterinarianAvatarParams = {
                    organization_id: this.loggedOrganization.id,
                    veterinarian_id: this.veterinarian.id,
                    avatar: formData,
                };

                organizationModule
                    .postOrganizationVeterinarianAvatar(params)
                    .then(() => {
                        snackbarModule.displaySuccess(`Votre avatar a bien été modifié !`);
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                    })
                    .finally(() => {
                        this.isSubmittingAvatar = false;
                        this.avatarDialog = false;
                    })
                ;
            }

        }
    }
