
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAccount,
        IOrganizationJoinRequest
    } from '@/types';

    import {
        AccountModule,
        SnackbarModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component<JoinRequestsTable>({})
    export default class JoinRequestsTable extends Vue {
        public isLoading: boolean = true;

        public updatingJoinRequest: boolean[] = [];

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @accountNamespace.Getter('loggedAccountJoinRequests')
        public loggedAccountJoinRequests!: IOrganizationJoinRequest[];

        public DATETIME_SHORT = DateTime.DATETIME_SHORT;

        get headers() {
            return [
                {
                    text: 'Etablissement',
                    value: 'organization',
                },
                {
                    text: 'Adresse',
                    value: 'address',
                },
                {
                    text: 'Expire le',
                    value: 'valid_until',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                },
            ];
        }

        public cancelJoinRequest(joinRequest: any, status: string) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.updatingJoinRequest[joinRequest.id] = true;

            getModule(AccountModule, this.$store)
                .cancelJoinRequest({
                    join_request_id: joinRequest.id,
                    account_id: this.loggedAccount.id,
                })
                .then(() => {
                    snackbarModule.displaySuccess('La demande a bien été annulée');
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => this.updatingJoinRequest[joinRequest.id] = false)
            ;
        }

        public created() {
            getModule(AccountModule, this.$store)
                .fetchAccountJoinRequests()
                .then(() => this.isLoading = false)
            ;
        }
    }
