
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import JoinRequestsTable from '@/components/account-organizations/JoinRequestsTable.vue';
    import OrganizationsTable from '@/components/account-organizations/OrganizationsTable.vue';

    @Component<AccountOrganizations>({
        components: {
            JoinRequestsTable,
            OrganizationsTable,
        },
    })
    export default class AccountOrganizations extends Vue {}
