
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';

    import {
        IAccount,
        IUser,
        IUpdateAccountData,
    } from '@/types';

    import {
        AccountModule,
        SnackbarModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component<AccountAuthentication>({})
    export default class AccountAuthentication extends Vue {
        public isSubmittingAuthentication: boolean = false;
        public formValid: boolean = false;
        public emailRules = formHelper.getEmailsRules();
        public passwordRules = formHelper.getPasswordRules(false);
        public hisOkPassword: boolean = false;

        public password: string = '';
        public passwordConfirm: string = '';
        public showPasswords: boolean = false;
        public innerModel!: IAccount;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        get isSamePasswords(): boolean {
            this.rulepass();
            if (this.password === this.passwordConfirm) {
                return true;
            } else {
                this.hisOkPassword = false;
                return false;
            }
        }

        public rulepass() {  
            if (this.password.length >= 12 && /\d/.test(this.password) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.password)) {
                this.hisOkPassword = true;
            } else {
                this.hisOkPassword = false;
            }
        }

        public submitAuthentication() {
            this.isSubmittingAuthentication = true;
            const accountModule = getModule(AccountModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            const params: IUpdateAccountData = {
                email: this.innerModel.email,
            };

            if (this.password) {
                params.password_raw = this.password;
            }

            accountModule
                .updateAccount(params as IUpdateAccountData)
                .then((user: IUser) => {
                    snackbarModule.displaySuccess(`Votre compte a bien été modifié !`);
                    this.$emit('input', user);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => {
                    this.isSubmittingAuthentication = false;
                });
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.loggedAccount),
            };
        }
        @Watch('loggedAccount', {immediate: true})
        private onLoggedAccountChange(newVal: IAccount) {
            this.innerModel = Object.assign({}, newVal);
        }
    }
